import React, { useEffect, useState } from 'react';
import Page from '../../../components/page';
import AppBar from '../../../components/appBar';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../../../components/button';
import { LoadingMask, showConfirm, showToast } from '../../../utils/utils';
import { apiEditEm, apiGetEmList, apiLogout } from '../../../api';
import main, { AppLocalKey } from '../../../main';
import NoData from '../../../components/noData';

export default function EmListPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [list, setList] = useState<EM[]>([]);

  useEffect(() => {
    // 优先验证绑卡，如果链接内带卡号 直接去绑卡
    if (!!main.nextBindEm) {
      navigate('/add-em', { state: { backToOut: true } });
      return;
    }
    loadEmList();
  }, []); // eslint-disable-line

  async function loadEmList() {
    LoadingMask.show('加载中...');
    try {
      let res = await apiGetEmList();
      let data = res.data;
      if (data.code === 200) {
        setList(data.data ?? []);
      } else {
        showToast(data.msg ?? '加载列表失败');
      }
    } catch (e: any) {
      showToast(e.message ?? '加载列表失败');
    } finally {
      LoadingMask.hide();
    }
  }

  async function handleBack() {
    if (location.state?.backToOut) {
      let modalRes = await showConfirm({ title: '提示', content: '确定要退出登录吗?' });
      if (!modalRes) return;
      LoadingMask.show('正在退出...');
      try {
        let res = await apiLogout();
        let data = res.data;
        if (data.code === 200) {
          showToast(data.msg ?? '退出登录成功');
          main.user = undefined;
          main.currentEm = undefined;
          main.loginKey = undefined;
          localStorage.removeItem(AppLocalKey.loginToken);
          navigate('/login', { replace: true });
        } else {
          showToast(data.msg ?? '退出失败');
        }
      } catch (e: any) {
        showToast(e.message ?? '退出失败');
      } finally {
        LoadingMask.hide();
      }
    } else {
      navigate(-1);
    }
  }

  /**
   * 设置默认设备
   * @param em 设备号
   */
  async function handleSelectCurrentEm(item: EM) {
    LoadingMask.show('正在设置...');
    try {
      let res = await apiEditEm(item.id!, item.userRemark, true);
      let data = res.data;
      if (data.code === 200) {
        showToast(data.msg ?? '设置成功');
        if (location.state?.backToOut) {
          navigate('/home', { replace: true });
        } else {
          navigate(-1);
        }
      } else {
        showToast(data.msg ?? '设置失败');
      }
    } catch (e: any) {
      showToast(e.message ?? '设置失败');
    } finally {
      LoadingMask.hide();
    }
  }
  return (
    <Page
      className="d-flex-c"
      contentClassName="flex-1 d-flex-c h-1"
      appBar={<AppBar title="选择设备" onBack={handleBack} />}
    >
      <div className="h-10 bg-gray"></div>
      <div className="bg-white font-size-16 font-widget-600 pl-20 pt-20">绑定手机号:{main.user!.phonenumber ?? ''}</div>
      <div className="flex-1 overflow-y h-1">
        {!list.length && <NoData tips="暂无设备" />}
        {list.map((item, idx) => (
          <div
            key={item.id! + idx}
            className="d-flex-r ai-center p-16 border border-color-gray border-radius-5 mt-12 ml-20 mr-20"
            onClick={() => handleSelectCurrentEm(item)}
          >
            <img
              className="mr-20 icon-24"
              src={`/pic/icon_em_${item.status === 'Y' ? 'enable' : 'disabled'}.png`}
              alt=""
            />
            <div className="flex-1 mr-30">
              <div className="d-flex-r ai-center">
                <div className="mr-20 flex-1">设备号:{item.emNumber}</div>
                <div className={`w-8 h-8 border-radius-8 bg-${item.status === 'Y' ? 'primary' : 'red'} mr-10`}></div>
                <div>{item.status === 'Y' ? '正常' : '停机'}</div>
              </div>
              <div className="d-flex-r ai-center jc-between text-color-9 font-size-12 mt-5">
                <div>设备描述:{item.userRemark}</div>
                <div className="text-color-3">余额:{item.cardDetailWalletVo?.availableAmount ?? 0}元</div>
              </div>
            </div>
            <img
              className={`${main.currentEm?.emNumber !== item.emNumber ? 'view-opacity-0' : ''} icon-18`}
              src="/pic/icon_radio_checked.png"
              alt=""
            />
          </div>
        ))}
      </div>
      <div className="pt-10 pb-100 pl-20 pr-20">
        <Button className="mb-10" type="primary" size="md" shape="circle" long onClick={() => navigate('/add-em')}>
          添加设备
        </Button>
        <Button type="gray" size="md" long shape="circle" onClick={() => navigate('/remove-em')}>
          解绑设备
        </Button>
      </div>
    </Page>
  );
}
