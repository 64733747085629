import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiGetPayOrderList } from '../../../api';
import main from '../../../main';
import { PayOrderStatus } from '../../../utils/dict';
import Page from '../../../components/page';
import AppBar from '../../../components/appBar';
import TabBar from '../../../components/tabBar';
import NoData from '../../../components/noData';
import { LoadingMask, showToast } from '../../../utils/utils';

export default function PayOrderListPage() {
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);
  const [list, setList] = useState<PayOrder[]>([]);
  useEffect(() => {
    loadList();
  }, []); // eslint-disable-line

  async function loadList() {
    LoadingMask.show('加载中...');
    try {
      let res = await apiGetPayOrderList(main.currentEm?.id!);
      let data = res.data;
      if (data.code === 200) {
        setList(data.rows ?? []);
      } else {
        showToast(data.msg ?? '加载列表失败');
      }
    } catch (e: any) {
      showToast(e.message ?? '加载列表失败');
    } finally {
      LoadingMask.hide();
    }
  }
  let tabValueList = PayOrderStatus.getList();
  let tabLabelList: string[] = tabValueList.map((i) => PayOrderStatus.getStatusStr(i));
  let _list = list;
  if (tabValueList[tabValue] !== PayOrderStatus.all) {
    _list = _list.filter((i) => i.status === tabValueList[tabValue]);
  }
  return (
    <Page className="d-flex-c" contentClassName="flex-1 d-flex-c h-1" appBar={<AppBar title="我的订单" />}>
      <TabBar
        className="mt-10 border-bottom"
        options={tabLabelList}
        value={tabLabelList[tabValue as number]}
        onChange={(v) => setTabValue(tabLabelList.indexOf(v))}
      />
      <div className="flex-1 h-1 overflow-y bg-gray p-20">
        {!_list.length && <NoData tips="暂无订单" />}
        {!!_list.length &&
          _list.map((i) => (
            <div
              key={i.id}
              className="bg-white border-radius-8 p-10 mb-10"
              onClick={() => navigate('/pay-order-detail', { state: i.id })}
            >
              <div className="d-flex-r ai-center mb-10">
                <div className="font-size-12 text-color-9 flex-1">订单编号{i.id}</div>
                <div className={`text-color-${PayOrderStatus.getStatusColor(i.status!)}`}>
                  {PayOrderStatus.getStatusStr(i.status!)}
                </div>
              </div>
              <div className="d-flex-r ai-start mb-10">
                <img className="icon-60" src="/pic/icon_coupon_flow_active.png" alt="" />
                <div className="flex-1 h-60 d-flex-c jc-between pt-5 pb-5">
                  <div className="d-flex-r">
                    <div className="flex-1 omit-text-2 font-widget-600">{i.packDetailName}</div>
                    <div>¥{i.totalPrice}</div>
                  </div>
                  <div className="d-flex-r font-size-12 text-color-9 jc-end">X{i.number}</div>
                </div>
              </div>
              <div className="d-flex-r ai-center">
                <div className="font-size-12 text-color-9 flex-1">设备号:{i.emNumber}</div>
                <div className="font-size-12 mr-10">
                  {i.status === PayOrderStatus.wait || i.status === PayOrderStatus.close ? '侍' : '实'}付款
                </div>
                <div className="text-color-p font-size-16">¥{i.realPrice}</div>
              </div>
            </div>
          ))}
      </div>
    </Page>
  );
}
