import { PayType } from './dict';

type PayRes = {
  /**
   * 200 =>成功
   * 300 =>等待
   * 500 =>失败
   */
  code: number;
  title?: string;
  msg: string;
  url?: string;
};

export default async function showPayPage(data: any, payType: string): Promise<PayRes> {
  if (payType === PayType.wechatJsPay || payType === PayType.gatherPayJsWechat || payType === PayType.gmeJsPay) {
    return await wechatJsPay(data);
  } else if (payType === PayType.sdbPayFastWeb) {
    return await sandePay(data);
  } else if (payType === PayType.aliPage) {
    return {
      code: 300,
      msg: '正在支付',
      title: '收银台',
      url:
        'https://wsl.10086iot.net/aliImg/alipay.html?price=' +
        data.packageValue +
        '&payUrl=' +
        encodeURIComponent(data.payParam),
      // url: data.payParam,
    };
  }
  return { code: 500, msg: '支付方式未配置,请联系客服' };
}

function wechatJsPay(data: any) {
  return new Promise<PayRes>((resolve, reject) => {
    // @ts-ignore
    WeixinJSBridge.invoke(
      'getBrandWCPayRequest',
      {
        appId: data.appId, //动态获取初始化请求中返回的pay_info参数中appId值
        timeStamp: data.timeStamp, //动态获取初始化请求中返回的pay_info参数中timeStamp值
        nonceStr: data.nonceStr, //动态获取初始化请求中返回的pay_info参数中nonceStr值
        package: data.packageValue, //动态获取初始化请求中返回的pay_info参数中package值
        signType: data.signType, //动态获取初始化请求中返回的pay_info参数中signType值
        paySign: data.paySign,
      },

      function (res: any) {
        if (res.err_msg === 'get_brand_wcpay_request:ok') {
          resolve({ code: 200, msg: '支付成功' });
        } else {
          resolve({ code: 500, msg: '支付失败' });
        }
      }
    );
  });
}

async function sandePay(data: any) {
  let sdbSign = data.sdbSign;
  if (!sdbSign) {
    return { code: 500, msg: '缺少支付参数' };
  }
  let needUriEncode = [
    'goods_name',
    'notify_url',
    'return_url',
    'pay_extra',
    'meta_option',
    'extend',
    'merch_extend_params',
    'sign',
  ];
  let url = 'https://sandcash.mixienet.com.cn/pay/h5/fastpayment';
  let keys = Object.keys(sdbSign);

  let args: string[] = [];
  for (let key of keys) {
    if (needUriEncode.includes(key)) {
      args.push(`${key}=${encodeURIComponent(sdbSign[key])}`);
    } else {
      args.push(`${key}=${sdbSign[key]}`);
    }
  }
  url += '?' + args.join('&');
  return { code: 300, msg: '正在支付', url };
}
