import { useEffect, useRef, useState } from 'react';
import Button from '../../components/button';

// const favoriteSteps = [
//   {
//     pic: '/pic/favorite_1.png',
//     title: '点击右上角三个点',
//   },
//   {
//     pic: '/pic/favorite_2.png',
//     title: '收藏网址',
//   },
//   {
//     pic: '/pic/favorite_3.png',
//     title: '查看网址',
//   },
//   {
//     pic: '/pic/favorite_4.png',
//     title: '打开网址',
//   },
// ];
interface Props {
  onOk: (v: any) => void;
}
const time = 3;
export default function FavoriteDialog({ onOk }: Props) {
  const ref = useRef<{ timer?: NodeJS.Timeout; time: number }>({ time });
  const [showTime, setShowTime] = useState(time);
  useEffect(() => {
    startTimer();
  }, []);

  function startTimer() {
    if (!ref.current.timer) {
      ref.current.timer = setInterval(() => {
        if (ref.current.time > 0) {
          ref.current.time--;
          setShowTime(ref.current.time);
        } else {
          clearInterval(ref.current.timer);
        }
      }, 1000);
    }
  }

  function handleClose() {
    if (showTime > 0) return;
    onOk(0);
  }

  return (
    <div className="favorite-dialog d-flex-c">
      <div className="flex-1"></div>
      <div className="text-right pr-10 mb-20">
        <img className="icon-24" src="/pic/icon_close_white.png" alt="" onClick={handleClose} />
      </div>
      <div className="step-img-block bg-white border-radius-10 d-flex-c">
        <div className="font-size-16 pl-10 pt-10">收藏流程</div>
        {/* <Slider autoplay>
          {favoriteSteps.map((item, idx: number) => (
            <div key={item.pic + idx} className="slider-img-block">
              <div className="font-14 h-40 mt-10 mb-10">
                {item.title.split(',').map((item: any, index: number) => (
                  <div key={item + index}>
                    {item}
                    <br />
                  </div>
                ))}
              </div>
              <img src={item.pic} alt="" className="slider-img" />
              <div className="slider-index">
                {idx + 1} / {favoriteSteps.length}
              </div>
            </div>
          ))}
        </Slider> */}
        <div className="flex-1 h-1 d-flex-r ai-center jc-center">
          <img className="bh-90" src="/pic/gif_favorite.gif" alt="" />
        </div>
      </div>
      <div className="vw-100 d-flex-c ai-center mt-10">
        <Button type={showTime > 0 ? 'gray' : 'primary'} size="md" onClick={handleClose}>
          <div className="w-140">{showTime > 0 ? `关闭${showTime}s` : `关闭`}</div>
        </Button>
      </div>
      <div className="flex-1"></div>
    </div>
  );
}
