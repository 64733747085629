import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { apiGetCardLibraryCustomer, apiGetLoginCustomerUrl } from '../../api';
import AppBar from '../../components/appBar';
import Page from '../../components/page';
import main from '../../main';
export const _customerUrl = 'https://work.weixin.qq.com/kfid/kfc6799b3ca6f22f1a8';

export default function CustomerPage() {
  const navigate = useNavigate();
  const { emNumber } = useLocation().state || {};
  const [list, setList] = useState<CustomServiceUrl[]>([]);
  const [loaded, setLoaded] = useState(false);
  // 设备号在卡库中的客服链接
  const [cardLibarayCustomer, setCardLibarayCustomer] = useState<string>();

  useEffect(() => {
    loadCustomer();
  }, []); // eslint-disable-line

  async function loadCustomer() {
    // 是否找到卡库中的客服链接了
    let finded = false;
    let _emNumber = emNumber || main.currentEm?.emNumber;
    if (!!_emNumber) {
      try {
        let res = await apiGetCardLibraryCustomer(_emNumber);
        let data = res.data;
        if (data.code === 200) {
          let _url = (data.data ?? []).find((i: any) => !!i.serverUrl)?.serverUrl;
          if (!!_url) {
            finded = true;
            setCardLibarayCustomer(_url);
          }
        }
      } catch (e: any) {}

      // 找到卡库中的客服链接了 直接用
      if (finded) {
        setLoaded(true);
        return;
      }
    }

    try {
      let res = await apiGetLoginCustomerUrl();
      let data = res.data;
      if (data.code === 200) {
        setList(data.rows ?? []);
      }
    } catch (e) {
    } finally {
      setLoaded(true);
    }
  }

  let useUrl = '';
  if (loaded) {
    if (main.configList) {
      let config = main.configList.find((i) => i.configKey === main.wslCustomerUrl);
      if (config) {
        useUrl = config.configValue!;
      }
    }

    let find = false;
    if (list.length && main.currentEm?.tagNameList?.length) {
      for (let userTag of main.currentEm.tagNameList!) {
        for (let customUrl of list) {
          if (customUrl.tagNameList && customUrl.tagNameList.includes(userTag)) {
            useUrl = customUrl.serviceUrl!;
            find = true;
            break;
          }
        }
        if (find) break;
      }
    }

    /**
     * 取值优先级：
     * 0. 找卡库中的客服链接
     * 1. 当前设备标签匹配的客服链接
     * 2. 系统参数配置的客服链接
     * 3. config.js配置的客服链接
     * 4. 默认的顶部写死的客服链接
     */
    // 优先级 如果卡库链接存在，必定用卡库链接
    if (!!cardLibarayCustomer) {
      useUrl = cardLibarayCustomer;
    } else if (!useUrl) {
      useUrl = main.customer || _customerUrl;
    }
    if (useUrl?.includes('work.weixin.qq.com')) {
      window.location.href = useUrl;
      setTimeout(() => {
        navigate(-1);
      }, 1000);
    }
  }
  return (
    <Page className="d-flex-c" contentClassName="flex-1 h-1" appBar={<AppBar title="在线客服" />}>
      {loaded ? (
        <iframe className="bw-100 bh-100 border-none" title="客服" src={useUrl} />
      ) : (
        <div className="d-flex-r bh-100 ai-center jc-center">正在加载...</div>
      )}
    </Page>
  );
}
