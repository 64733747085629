import { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  apiBalanceRecharge,
  apiGetBalanceRechargePayInfo,
  apiGetDirectRechargeList,
  apiGetPayTypeConfigList,
  apiGetUserInfo,
} from '../../api';
import AppBar from '../../components/appBar';
import Page from '../../components/page';
import RadioBox from '../../components/radioBox';
import main from '../../main';
import { PayType } from '../../utils/dict';
import showPayPage from '../../utils/pay';
import { LoadingMask, formatNumber, modelParsePrice, showConfirm, showToast } from '../../utils/utils';

export default function BalanceRechargePage() {
  const navigate = useNavigate();

  // 充值优惠信息
  // const [reduction, setReduction] = useState<any>({});
  // 充值面值列表
  const [preMoneyList, setPreMoneyList] = useState<DirectRecharge[]>([]);
  // 已选充值面值
  const [preMoney, setPreMoney] = useState<DirectRecharge>();

  // 支付方式列表
  const [payConfigList, setPayConfigList] = useState<PayTypeConfig[]>([]);
  // 已选支付方式
  const [payType, setPayType] = useState<string>();
  const [wxReady, setWxReady] = useState(false);

  useEffect(() => {
    loadPayConfigList();
    loadDirectRechargeList();
    // loadPayConfigReduction();
    // @ts-ignore
    if (typeof WeixinJSBridge === 'undefined') {
      if (document.addEventListener) {
        document.addEventListener(
          'WeixinJSBridgeReady',
          () => {
            setWxReady(true);
          },
          false
        );
        // @ts-ignore
      } else if (document.attachEvent) {
        // @ts-ignore
        document.attachEvent('WeixinJSBridgeReady', () => {
          setWxReady(true);
        });
      }
    } else {
      setWxReady(true);
    }
  }, []); // eslint-disable-line

  /**
   * 获取支付配置列表
   */
  async function loadPayConfigList() {
    try {
      let res = await apiGetPayTypeConfigList();
      let data = res.data;
      if (data.code === 200) {
        let list: PayTypeConfig[] = data.data ?? [];
        list = list.filter((i) => i.payType !== PayType.balance);
        setPayConfigList(list);
        let payTypes = list.map((i: PayTypeConfig) => i.payType!);
        if (payTypes.length && !payType) {
          setPayType(payTypes[0]);
        }
      }
    } catch (e) {}
  }

  async function loadDirectRechargeList() {
    try {
      let res = await apiGetDirectRechargeList(main.currentEm!.id!);
      let data = res.data;
      if (data.code === 200) {
        let _list = data.data ?? [];
        setPreMoneyList(data.data ?? []);
        if (_list!.length && !preMoney) {
          setPreMoney(_list[0]);
        }
      }
    } catch (e: any) {}
  }

  // /**
  //  * 获取支付优惠
  //  */
  // async function loadPayConfigReduction() {
  //   try {
  //     let res = await apiGetPayConfigReduction(main.currentEm?.id!);
  //     let data = res.data;
  //     if (data.code === 200) {
  //       let _map: any = {};
  //       (data.data ?? []).forEach((i: any) => (_map[i.payType] = i));
  //       setReduction(_map);
  //     }
  //   } catch (e) {}
  // }

  async function handleRecharge() {
    if (!main.openId && !main.isDev) {
      return showToast('授权失败,请在微信内打开');
    }
    if (!main.currentEm?.id) {
      return showToast('请选择卡号');
    }
    if (!payType) {
      return showToast('请选择支付方式');
    }
    if (!preMoney) {
      return showToast('请选择充值金额');
    }
    LoadingMask.show('正在充值...');
    try {
      let res = await apiBalanceRecharge(main.currentEm.id, preMoney.payDetailId!, main.appId, main.openId!);
      let data = res.data;
      if (data.code === 200) {
        let _data = data.data;
        let orderId = _data.orderId;
        let payInfo = await apiGetBalanceRechargePayInfo(orderId, payType, main.openId!);
        let payInfoData = payInfo.data;
        if (payInfoData.code === 200) {
          let _data = payInfoData.data;
          if (payType === PayType.aliPage) {
            let payRes = await showPayPage(_data, payType);
            if (payRes.code === 300) {
              if (payRes.url) {
                LoadingMask.hide();
                window.location.href = payRes.url;
                await showConfirm({
                  title: '提示',
                  content: '是否支付完成?',
                  confirmBtnText: '支付完成',
                  cancelBtnText: '未完成',
                });
                navigate('/home', { replace: true });
                showToast('支付成功');
              }
              showToast(payRes.msg);
            } else if (payRes.code === 500) {
              showToast(payRes.msg);
            } else {
              showToast('数据还在准备,请稍后重试');
            }
          } else {
            if (wxReady) {
              let payRes = await showPayPage(_data, payType);
              if (payRes.code === 200) {
                navigate(-1);
                showToast('支付成功');
                try {
                  let res = await apiGetUserInfo(main.loginKey!);
                  let data = res.data;
                  if (data.code === 200) {
                    main.user = data.data.user;
                  }
                } catch (e: any) {
                  console.error(e);
                }
              } else if (payRes.code === 300) {
                if (payRes.url) {
                  LoadingMask.hide();
                  navigate('/customer', { state: { url: payRes.url, title: '银联支付', pay: true, back: true } });
                }
              } else {
                showToast('支付失败');
              }
            } else {
              showToast('数据还在准备,请稍后重试');
            }
          }
        } else {
          showToast(payInfoData.msg ?? '支付失败');
        }
      } else {
        showToast(data.msg ?? '支付失败');
      }
    } catch (e: any) {
      showToast(e.message ?? '支付失败');
    } finally {
      LoadingMask.hide();
    }
  }

  var textStrs: string[] = [];
  if (modelParsePrice(preMoney?.price ?? 0) > modelParsePrice(preMoney?.amount ?? 0)) {
    textStrs.push(`充${formatNumber(preMoney!.amount!)}元得${formatNumber(preMoney!.price!)}元(立即到账)`);
  }

  if (preMoney?.coupons) {
    for (var i of preMoney!.coupons) {
      textStrs.push(`送${i.couponName} X${formatNumber(i.couponNum ?? 0)}张`);
    }
  }
  let itemList: ReactNode[] = preMoneyList.map((item) => (
    <div
      className={`d-flex-c ai-center jc-center w-150 mb-20 h-70 mr-10 bg-gray pos-r border ${
        item.payDetailId === preMoney?.payDetailId ? ' border-color-origin-red bg-origin-red-t' : ''
      } border-radius-8`}
      key={item.payDetailId}
      onClick={() => setPreMoney(item)}
    >
      {/* 充 amount 得 price */}
      <div className=" text-color-origin-red">
        <span className="font-size-20">{item.price}</span>元
      </div>
      <div className="mt-5">售价:{item.amount}元</div>
      {modelParsePrice(item?.price ?? 0) > modelParsePrice(item?.amount ?? 0) && (
        <img src="/pic/icon_preferential.png" className="icon-30 pos-a r-0 t-0" alt="" />
      )}
    </div>
  ));
  if (textStrs.length > 0) {
    let idx = preMoneyList.findIndex((item) => item.payDetailId === preMoney?.payDetailId);
    let curr = preMoneyList[idx];
    let descView = (
      <div className="w-335 h-70 pos-r mb-20">
        <img className="bw-100 h-70 pos-a l-0 t-0" src="/pic/icon_recharge_desc_bg.png" alt="" />
        <div className="pos-r d-flex-r bw-100 bh-100">
          <div className="w-100 d-flex-c ai-center jc-center">
            <div className="text-color-origin-red">
              <span className="font-size-20">{curr?.price}</span>元
            </div>
            <div>价值</div>
          </div>
          <div className="flex-1 d-flex-c ai-start jc-center pl-10 mr-10">
            {textStrs.map((i, idx) => (
              <div key={idx}>
                {idx + 1}、{i}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
    itemList.splice((Math.floor(idx / 2) + 1) * 2, 0, descView);
  }
  return (
    <Page
      className="d-flex-c"
      contentClassName="flex-1 d-flex-c h-1 p-20 overflow-y"
      appBar={<AppBar title="余额充值" />}
    >
      <div className="d-shadow-p border-radius-40 pos-r">
        <img className="bw-100 bh-100 pos-a l-0 t-0 border-radius-20" src="/pic/icon_recharge_card.png" alt="" />
        <div className="pos-r p-20 bw-100 bh-100 d-flex-c text-color-white">
          <div className="font-size-12 mb-20">设备号：{main.currentEm!.emNumber}</div>
          <div className="font-size-48 text-center">
            {modelParsePrice(main.currentEm!.cardDetailWalletVo?.availableAmount ?? 0).toFixed(2)}
          </div>
          <div className="font-size-12 text-center">我的余额(元)</div>
        </div>
      </div>
      <div className="mb-20 mt-20 font-size-16">充值金额</div>
      <div className="d-flex-r flex-wrap jc-between">{itemList}</div>
      <div className="text-color-9 font-size-12">
        {!!textStrs.length && <div className="font-size-12">*参与规则</div>}

        {!!textStrs.length && <div>1、流量券需要主动使用,使用后在当月内生效;</div>}
        {!!textStrs.length && (
          <div className="font-size-12 font-color-9">2、抵扣券在购买套餐时主动选择,可抵扣相应金额;</div>
        )}
        {!!textStrs.length && (
          <div className="font-size-12 font-color-9 mb-10">3、赠送抵扣券只能用来购买基础套餐使用;</div>
        )}
      </div>

      <div className="mt-20 font-size-16">支付方式</div>
      {payConfigList.map((item, idx) => {
        let subLabel = item.slogan ?? '';
        // let reduce = reduction?.[item.payType!];
        // if (reduce && (reduce.reduction === 'all' || (reduce.reduction === 'first' && reduce.payCount <= 0))) {
        //   subLabel = `最高优惠${reduce.reductionAmount}元`;
        // }
        return (
          <div key={idx} className="mt-20 d-flex-r jc-between ai-center" onClick={() => setPayType(item.payType)}>
            <img className="w-20 mr-10" src={`/pic/${PayType.typeIcon(item.payType)}`} alt="" />
            <span>{item.name}</span>
            <div className="ml-10 font-size-12 text-color-red flex-1">{subLabel}</div>
            <RadioBox value={payType === item.payType} />
          </div>
        );
      })}
      <div
        className="mt-20 text-color-white bg-line-origin-red border-radius-40 p-10 text-center"
        onClick={handleRecharge}
      >
        立即支付￥{modelParsePrice(preMoney?.amount ?? 0).toFixed(2)}
      </div>
    </Page>
  );
}
